<style lang="scss">
@import "~@/assets/css/var";

.popup-class {
    font-size: 12px;
    max-width: 400px;
    max-height: 390px;
    overflow-y: auto;
    background: linear-gradient(180deg, #D1E0FC 0%, rgba(255, 255, 255, 1) 32%);

    &::-webkit-scrollbar {
        display: none;
    }

    &__i {
        color: $main;
    }
}

.el-select {
    .el-select__caret:first-child::before {
        content: "\e6e1";
    }

    .is-focus {
        .el-select__caret:first-child {
            transform: rotateZ(0deg);
        }
    }
}

.tetx_red {
    color: $red;
}

.tetx_yellow {
    color: $yellow;
}

.tetx_green {
    color: $green;
}

.tetx_bold {
    font-weight: bold;
}
</style>

<template>
    <div class="common-table" v-if="loaded">
        <div class="common-table__title flex">
            {{ $store.state.titleRefer[mainKey] }}
            <el-popover v-if="point.show" placement="right-start"  trigger="hover"
                popper-class="popup-class">
                <i slot="reference" class="el-icon-info popup-class__i" style="color: #45d75f;"></i>
                <i></i>
                <p class="through-page-tips" style="white-space: pre-wrap;">
                    {{ point.txt }}
                </p>
            </el-popover>
            <div style="display: inline; margin-left: 20px;"
                v-if="mainKey === 'flow_development_review_progress' || mainKey === 'rd_registration_acceptances'">
                <el-radio-group v-model="radio" @input="radioChange">
                    <el-radio-button label="1">注册受理</el-radio-button>
                    <el-radio-button label="2">研发追踪</el-radio-button>
                    <!-- <el-radio-button label="3">统计表</el-radio-button> -->
                </el-radio-group>
            </div>

            <div class="flex" style="display: inline; margin-left: 20px;"
                v-if="outSearchCols && outSearchCols.length > 0 && radio === '1'">
                <div v-for="col in outSearchCols" :key="col.id" style="margin-right: 5px;">
                    <el-input v-if="col.listQueryModel === $variable.searchType.LIKE" style="width: 220px"
                        v-model="outSearchForm[col.fieldName]" :placeholder="'请输入' + col.finalShowTxt"></el-input>

                    <el-select v-else-if="col.listQueryModel === $variable.searchType.SELECT"
                        v-model="outSearchForm[col.fieldName]" filterable remote :placeholder="'请选择' + col.finalShowTxt"
                        clearable multiple collapse-tags @focus="selectRemote(col, undefined, 'focus')" reserve-keyword
                        style="width: 240px" :remote-method="(argument) => { selectRemote(col, argument) }"
                        :loading="col.loading">
                        <el-option v-for="(child, key) in col.list" :key="key"
                            :value="col.useKey ? child.key : child.value" :label="child.value"></el-option>
                    </el-select>
                </div>
                <div style="margin-left: 5px;"><el-button type="primary" @click="outSearch">筛选</el-button></div>
            </div>
            <div class="flex" style=" margin-left: 20px;" v-if="mainKey === 'dr_interact'">
                <el-select v-model="drInteractValue" filterable remote placeholder="请选择药品" clearable
                    @focus="drInteractRemote(drInteractCol, undefined, 'focus')" reserve-keyword style="width: 240px"
                    :remote-method="(argument) => { drInteractRemote(drInteractCol, argument) }"
                    :loading="drInteractCol.loading">
                    <el-option v-for="(orr, key) in drInteractCol.list" :key="key" :value="orr.dataCode"
                        :label="orr.dataName"></el-option>
                </el-select>
                <div style="margin-left: 5px;"><el-button type="primary" @click="drInteractSearch">筛选</el-button></div>
            </div>
            <ts-table-operate :name="config.key" :table-name="config.key" v-if="cols.length > 0 && radio === '1'"
                :cols="cols" @handleFilter="handleFilter" is-export @handleSort="sort" @viewChange="viewChange"
                @leadingOut="leadingOut" show-export show-add @searchShowLeft="searchShowLeft"></ts-table-operate>
            <ts-table-operate v-else-if="radio === '3'" :cols="[]" table-name="aim_base_drugs_winning"
                @handleFilter="handleFilter" @searchShowLeft="searchShowLeft" @leadingOut="leadingOut" show-export
                show-add is-export @handleSort="sort">
            </ts-table-operate>
        </div>

        <div class="ts-table flex flex-item flex-column">
            <ts-table v-if="radio === '1'" ref="table" class="flex-item" :cols="cols" :data="list"
                :page-param="pageParam" :loading="loading" :name="config.key" @handleSort="sort"
                :error="pageParam.error" :error-code="error" @handleRowDbClickChange="handleRowDbClickChange" full
                @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
                <template #searchLeft>
                    <ts-search-left v-if="isSearchLeftOne && radio === '1'" :searchCols="searchCols" :name="config.key"
                        :table-name="config.key" :cols="cols" @searchClose="searchClose"
                        @handleFilter="handleFilter"></ts-search-left>
                </template>
                <template v-slot:slot_approvalNumber="{ props }">
                    <template v-if="mainKey === 'dr_domestic_drugs'">
                        <el-button type="text" @click="seeApprovalList(props.row)">{{ props.row.approvalNumber
                            }}</el-button>
                    </template>
                    <template v-else>
                        {{ props.row.approvalNumber }}
                    </template>
                </template>
                <!-- 药品生产企业数据库--编号 -->
                <template v-slot:slot_serialNumber="{ props }">
                    <template v-if="mainKey === 'co_drug_manufacturers'">
                        <el-button type="text" @click="clickNumber(props.row, 'serialNumber', 'openDate')">{{
        props.row.serialNumber }}</el-button>
                    </template>
                    <template v-else>
                        {{ props.row.serialNumber }}
                    </template>
                </template>
                <!-- GMP认证企业数据库/GSP认证企业数据库--证书编号 -->
                <template v-slot:slot_certificateNumber="{ props }">
                    <template v-if="mainKey === 'co_gmp_authentications' || mainKey === 'co_gsp_authentications'">
                        <el-button type="text" @click="clickNumber(props.row, 'certificateNumber', 'openingDate')">{{
        props.row.certificateNumber }}</el-button>
                    </template>
                    <!-- <template v-else>
                        {{ props.row.certificateNumber }}
                    </template> -->
                </template>
                <!-- 药品经营企业数据库--许可证编号 -->
                <template v-slot:slot_licenseNumber="{ props }">
                    <template v-if="mainKey === 'co_drug_handling_enterprises'">
                        <el-button type="text" @click="clickNumber(props.row, 'licenseNumber', 'openingDate')">{{
        props.row.licenseNumber }}</el-button>
                    </template>
                    <template v-else>
                        {{ props.row.licenseNumber }}
                    </template>
                </template>
                <template v-slot:slot_approvalNumberStateName="{ props }">
                    <template
                        v-if="(mainKey === 'dr_domestic_drugs' || mainKey === 'dr_imported_drugs') && props.row.approvalNumberStateName == '注销'">
                        <span class="tetx_red">{{ props.row.approvalNumberStateName }}</span>
                    </template>
                    <template v-else>
                        {{ props.row.approvalNumberStateName }}
                    </template>
                </template>

                <template v-slot:slot_registrationNumber="{ props }">
                    <template v-if="mainKey === 'dr_imported_drugs'">
                        <el-button type="text" @click="seeImportList(props.row)">{{ props.row.registrationNumber
                            }}</el-button>
                    </template>
                    <template v-else-if="mainKey === 'dr_material_drugs'">
                        <el-button type="text" @click="seeMaterialList(props.row)">{{ props.row.registrationNumber
                            }}</el-button>
                    </template>
                    <template v-else>
                        {{ props.row.registrationNumber }}
                    </template>
                </template>

                <!--药品招投标，中标省份-->
                <template v-slot:slot_priceState="{ props }">
                    <template v-if="mainKey === 'mainf_drug_winning_informations'">
                        <i class="el-icon-s-marketing clickable" :class="props.row.clicked ? 'txt-red' : 'txt-green'"
                            @click="showCanvas(props.row)" v-show="$store.state.user.id"
                            style="margin-right: 5px;font-size:16px"></i>
                        <el-popover v-if="props.row.priceState === '暴涨'" trigger="hover" placement="right-start"
                            width="230">
                            <el-link slot="reference" style="font-size: 11px;font-weight: bold" type="danger"
                                size="mini" :underline="false">暴涨</el-link>
                            <p class="through-page-tips through-page-tips__small">
                                最新中标价<span class="red">大于</span>均价<br>
                                最新中标价<span class="red">大于</span>上次中标价*1.5<br>
                                最新中标价<span class="red">大于等于</span>最高价<br>
                            </p>
                        </el-popover>
                        <el-popover v-else-if="props.row.priceState === '暴跌'" trigger="hover" placement="right-start"
                            width="230">
                            <el-link slot="reference" style="font-size: 11px;font-weight: bold" type="success"
                                size="mini" :underline="false">暴跌</el-link>
                            <p class="through-page-tips through-page-tips__small">
                                最新中标价<span class="red">小于</span>均价<br>
                                最新中标价<span class="red">小于</span>上次中标价*50%<br>
                                最新中标价<span class="red">小于等于</span>最低价<br>
                            </p>
                        </el-popover>
                        <el-popover v-else-if="props.row.priceState === '涨价'" trigger="hover" placement="right-start"
                            width="230">
                            <el-link slot="reference" style="font-size: 11px;font-weight: bold" type="danger"
                                size="mini" :underline="false">涨价</el-link>
                            <p class="through-page-tips through-page-tips__small">
                                最新中标价<span class="red">大于</span>均价<br>
                                最新中标价<span class="red">大于等于</span>上次中标价<br>
                                最新中标价<span class="red">小于</span>最高价<br>
                            </p>
                        </el-popover>
                        <el-popover v-else-if="props.row.priceState === '跌价'" trigger="hover" placement="right-start"
                            width="230">
                            <el-link slot="reference" style="font-size: 11px;font-weight: bold" type="success"
                                size="mini" :underline="false">跌价</el-link>
                            <p class="through-page-tips through-page-tips__small">
                                最新中标价<span class="red">小于</span>上次中标价<br>
                                最新中标价<span class="red">大于等于</span>最低价<br>
                            </p>
                        </el-popover>
                        <el-popover v-else-if="props.row.priceState === '持平'" trigger="hover" placement="right-start"
                            width="230">
                            <el-link slot="reference" style="font-size: 11px;font-weight: bold" size="mini"
                                :underline="false">持平</el-link>
                            <p class="through-page-tips through-page-tips__small">
                                最新中标价<span class="red">大于等于</span>均价<br>
                                最新中标价<span class="red">等于</span>上次中标价<br>
                                最新中标价<span class="red">小于等于</span>最高价<br>
                            </p>
                        </el-popover>
                    </template>
                </template>
                <!--药物相互作用，联用机制-->
                <template v-slot:slot_jointMechanism="{ props }">
                    <template v-if="mainKey === 'dr_interact'">
                        <span class="tetx_yellow tetx_bold" v-if="props.row.jointMechanism === '谨慎合用（P）'">{{
        props.row.jointMechanism }}</span>
                        <span class="tetx_green tetx_bold" v-else-if="props.row.jointMechanism === '可以合用（C）'">{{
        props.row.jointMechanism }}</span>
                        <span class="tetx_red tetx_bold" v-else-if="props.row.jointMechanism === '避免合用（A）'">{{
        props.row.jointMechanism }}</span>
                    </template>
                </template>
                <!--医保目录，执行情况-->
                <template v-slot:slot_executiveCondition="{ props }">
                    <template v-if="mainKey === 'du_health_insurance_directories'">
                        <span class="tetx_green tetx_bold" v-if="props.row.executiveCondition === '执行'">{{
        props.row.executiveCondition }}</span>
                        <span class="tetx_red tetx_bold" v-else>{{ props.row.executiveCondition }}</span>
                    </template>
                </template>
            </ts-table>
            <dev-trace v-else-if="radio === '2'"></dev-trace>
            <statistical-table ref="statisticalTable" v-else-if="radio === '3'" :mainKey="mainKey"
                :isSearchLeftThree="isSearchLeftThree" @searchClose="searchClose"></statistical-table>
        </div>

        <ts-dialog :show.sync="historyParam.show" :title="historyParam.title" hide-footer :width="historyParam.width">
            <ts-table v-if="historyParam.show" :cols="historyParam.cols" :data="historyParam.list" height="400"
                hide-page hide-detail :loading="historyParam.loading"></ts-table>
        </ts-dialog>

        <ts-dialog :show.sync="bidDialog.show" title="价格趋势" width="1000px" close-on-click-modal hide-footer>
            <my-bid-compare v-if="bidDialog.show" :row="bidDialog.source" type="common"></my-bid-compare>
        </ts-dialog>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
import MyBidCompare from '@/components/MyBidCompare.vue'
import DevTrace from './developTrace.vue'
import statisticalTable from './statisticalTable.vue'
import TsSearchLeft from '../../components/TsSearchLeft.vue'
export default {
    components: { MyBidCompare, DevTrace, TsSearchLeft, statisticalTable },
    props: ['mainKey'],
    mixins: [myMixin],
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: '',
            pageName: '',

            historyParam: {
                width: '',
                title: '',
                show: false,
                loading: false,
                cols: [],
                list: [],
            },

            bidDialog: {
                show: false,
                source: {},
                txt: '',
            },

            point: {
                show: false,
                txt: '',
            },

            radio: '1',
            visible: false,

            isSearchLeftOne: false,
            isSearchLeftThree: false,

        }
    },
    watch: {
        'bidDialog.show'(val) {
            if (!val && this.bidDialog.source.id) {
                this.bidDialog.source.clicked = false
            }
        },
    },
    created() {
        // 是否显示指引
        this.$api.get('sys/sysTableGuide/findSysTableGuideByTableName', { tableName: this.mainKey })
            .then(res => {
                if (res.data && res.data.guidInfo) {
                    this.point.show = true
                    this.point.txt = res.data.guidInfo
                }
            })

        this.pageId = this.mainKey
        this.pageName = this.$store.state.titleRefer[this.mainKey]

        this.$help.socket.send(this)
    },
    methods: {
        radioChange() {
            this.$nextTick(() => {
                if (this.radio === '1') {
                    this.initFirst()
                }
            })
        },
        //左侧筛选
        searchShowLeft() {
            this.setNone(true)
        },
        //关闭左侧筛选框
        searchClose() {
            this.setNone(false)
        },
        setNone(falg) {
            if (this.radio === '1') {
                this.isSearchLeftOne = falg
            } else if (this.radio === '3') {
                this.isSearchLeftThree = falg
            }
        },
        add() {
            this.dialog.show = true
            this.dialog.loading = false
            this.dialog.edit = false
        },

        // 原料药品查看登记号历史记录
        seeMaterialList(form) {
            this.historyParam.title = `${form.registrationNumber}变更信息`
            this.historyParam.width = '500px'
            this.historyParam.show = true
            this.historyParam.loading = true
            this.historyParam.list = []
            this.historyParam.cols = [
                { fieldName: 'updateTime', name: '更新日期' },
                { fieldName: 'approvalResults', name: '与制剂共同审批审评结果' },
                { fieldName: 'remark', name: '状态' },
            ].map(item => {
                item.listView = '是'
                return item
            })
            this.$api.post(this.currentParam.page, {
                page: { pageNo: 1, pageSize: 1000 },
                sort: { updateDate: "asc" },
                conditionList: [
                    {
                        name: "registrationNumber",
                        op: "eq",
                        value: form.registrationNumber
                    }
                ],
            })
                .then(res => {
                    this.historyParam.list = res.data.records
                })
                .finally(() => {
                    this.historyParam.loading = false
                })
        },

        // 国产药品数据库查看批准文号历史记录
        seeApprovalList(form) {
            this.historyParam.title = `${form.approvalNumber}变更信息`
            this.historyParam.width = '1000px'
            this.historyParam.show = true
            this.historyParam.loading = true
            this.historyParam.list = []
            this.historyParam.cols = [
                { fieldName: 'approvalDate', name: '批准日期', maxWidth: 100 },
                { fieldName: 'comName', name: '药品通用名' },
                { fieldName: 'unifySpec', name: '统一规格', maxWidth: 120 },
                { fieldName: 'unifyDose', name: '统一剂型', maxWidth: 120 },
                { fieldName: 'compName', name: '企业名称' },
                { fieldName: 'marketPermitHolder', name: '上市许可持有人' },
                { fieldName: 'productionUnit', name: '生产单位' },
            ].map(item => {
                item.listView = '是'
                return item
            })
            this.$api.post(this.currentParam.page, {
                page: { pageNo: 1, pageSize: 1000 },
                sort: { updateTime: "asc" },
                conditionList: [
                    {
                        name: "approvalNumber",
                        op: "eq",
                        value: form.approvalNumber
                    }
                ],
            })
                .then(res => {
                    this.historyParam.list = res.data.records
                })
                .finally(() => {
                    this.historyParam.loading = false
                })
        },

        // 进口药品数据库查看注册证号历史记录
        seeImportList(form) {
            this.historyParam.title = `${form.registrationNumber}变更信息`
            this.historyParam.width = '1100px'
            this.historyParam.show = true
            this.historyParam.loading = true
            this.historyParam.list = []
            this.historyParam.cols = [
                { fieldName: 'approvalDate', name: '发证日期', maxWidth: 100 },
                { fieldName: 'comName', name: '药品通用名' },
                { fieldName: 'unifySpec', name: '统一规格', maxWidth: 120 },
                { fieldName: 'unifyDose', name: '统一剂型', maxWidth: 120 },
                { fieldName: 'compName', name: '生产厂商' },
                { fieldName: 'licenseeCh', name: '上市许可持有人' },
                { fieldName: 'subPackagingEnterpriseName', name: '分包装企业' },
            ].map(item => {
                item.listView = '是'
                return item
            })
            this.$api.post(this.currentParam.page, {
                page: { pageNo: 1, pageSize: 1000 },
                sort: { updateTime: "asc" },
                conditionList: [
                    {
                        name: "registrationNumber",
                        op: "eq",
                        value: form.registrationNumber
                    }
                ],
            })
                .then(res => {
                    this.historyParam.list = res.data.records
                })
                .finally(() => {
                    this.historyParam.loading = false
                })
        },
        //点击编号实现弹窗
        clickNumber(form, key, time) {
            let arr = []
            let str = '1100px'
            if (key === 'serialNumber') {   //药品生产企业数据库
                arr = [
                    { fieldName: 'socialCredit', name: '组织机构代码' },
                    { fieldName: 'compName', name: '生产企业' },
                    { fieldName: 'openDate', name: '发证日期', maxWidth: 120 },
                    { fieldName: 'dateExpiry', name: '有效期', maxWidth: 120 },
                ]
            } else if (key === 'certificateNumber') {
                if (this.mainKey === 'co_gmp_authentications') {  //GMP认证企业数据库
                    str = '800px'
                    arr = [
                        { fieldName: 'compName', name: '企业名称' },
                        { fieldName: 'openingDate', name: '发证日期', maxWidth: 120 },
                        { fieldName: 'expirationDate', name: '有效期', maxWidth: 120 },
                        { fieldName: 'approvalExtensionDate', name: '批准延续', maxWidth: 120 },
                        { fieldName: 'extendTo', name: '有效期延续', maxWidth: 120 },
                    ]
                } else if (this.mainKey === 'co_gsp_authentications') { //GSP认证企业数据库
                    str = '600px'
                    arr = [
                        { fieldName: 'compName', name: '企业名称' },
                        { fieldName: 'openingDate', name: '发证日期', maxWidth: 120 },
                        { fieldName: 'expirationDate', name: '有效期', maxWidth: 120 },
                    ]
                }

            } else if (key === 'licenseNumber') { //药品经营企业数据库
                str = '600px'
                arr = [
                    { fieldName: 'compName', name: '企业名称' },
                    { fieldName: 'openingDate', name: '发证日期', maxWidth: 120 },
                    { fieldName: 'validityPeriod', name: '有效期', maxWidth: 120 },
                ]
            }
            this.historyParam.title = `${form[key]}变更信息`
            this.historyParam.width = str
            this.historyParam.show = true
            this.historyParam.loading = true
            this.historyParam.list = []
            this.historyParam.cols = arr.map(item => {
                item.listView = '是'
                return item
            })
            this.$api.post(this.currentParam.page, {
                page: { pageNo: 1, pageSize: 1000 },
                sort: { [time]: "asc" },
                conditionList: [
                    {
                        name: key,
                        op: "eq",
                        value: form[key],
                    }
                ],
            })
                .then(res => {
                    this.historyParam.list = res.data.records
                })
                .finally(() => {
                    this.historyParam.loading = false
                })
        },

        showCanvas(form) {
            this.bidDialog.show = true
            this.bidDialog.source = form
            this.$set(form, 'clicked', true)
        },
        showCanvasBack(form) {
            this.bidDialog.show = true
            this.bidDialog.title = `${form.winningProvince || '全国'}-${form.compName}-${form.comName}${form.unifySpec}（${form.conversionRatio}）价格趋势`
            this.bidDialog.source = form
            this.$api.get('mainf/mainfDrugWinningInformations/windingPriceTrend', { id: form.id })
                .then(res => {
                    const myChart = window.echarts.init(document.getElementById('canvas-page'))
                    myChart.clear()

                    if (res.data && res.data.length > 0) {
                        myChart.setOption({
                            tooltip: {
                                trigger: 'axis',
                            },
                            xAxis: [{
                                type: 'category',
                                data: res.data.map(item => item.winningTime),

                            },],
                            yAxis: [{
                                type: 'value',
                                scale: true,
                                axisTick: { show: false },
                                axisLine: { show: false },
                                splitLine: {
                                    lineStyle: {
                                        type: 'dashed'
                                    }
                                },

                            },],
                            series: [{
                                type: 'line',
                                data: res.data.map(item => item.price).map(item => {
                                    if (item == 0) {
                                        item = ''
                                    }
                                    return item
                                }),
                                smooth: true,
                                connectNulls: true,
                            },],
                        })
                    }
                })
        },

        listComputed(list) {
            return list.map(item => {
                if (this.mainKey === 'du_instruction_books') {
                    if (item.componentLink) {
                        const arr = JSON.parse(item.componentLink)
                        arr.sort((orr1, orr2) => {
                            return orr2.name.length - orr1.name.length
                        })
                        arr.forEach((j, i) => {
                            item.component = item.component.replace(j.name, `##{${i}}##`)
                        })
                        item.component = this.setObj(item.component.split('##'), arr)
                    }
                    if (item.indicationLink) {
                        const arr = JSON.parse(item.indicationLink)
                        arr.sort((orr1, orr2) => {
                            return orr2.name.length - orr1.name.length
                        })
                        arr.forEach((j, i) => {
                            item.indication = item.indication.replace(j.commonName || j.name, `##{${i}}##`)
                        })
                        item.indication = this.setObj(item.indication.split('##'), arr)
                    }
                }
                return item
            })
        },
        setObj(obj, list) {
            return obj.map(orr => {
                if (orr.indexOf("{") != -1) {
                    const t = orr.replace("{", "").replace("}", "")
                    orr = list[t]
                }
                return orr
            })
        },
    },
}
</script>